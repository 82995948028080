<template>
    <div>
        <img class="example-headImg" src="../assets/image/example/04-0banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">快消零售</div>
            <div class="head-explain-content">优化供应链组织结构，为业务转型提供全面支持</div>
        </div>
        <div class="container-1" style="display:flex;margin-bottom: 10rem;">
            <div class="industry-box">
                <div class="card">
                    <img src="../assets/image/example/04-0数字化生产.png"/>
                    <div class="title1">数字化生产</div>
                    <div class="title2">打造透明、高效、稳定的数字化生产管理平台</div>
                    <div class="title3" @click="jumpRout('/digital-production','2')">了解详情</div>
                </div>
                <div class="card">
                    <img src="../assets/image/example/04-0zhineng.png"/>
                    <div class="title1">智能仓储</div>
                    <div class="title2">布局原料赋码，助力品质生产</div>
                    <div class="title3" @click="jumpRout('/intelligent-warehousing','2')">了解详情</div>
                </div>
                <div class="card">
                    <img src="../assets/image/example/04-0APS.png"/>
                    <div class="title1">APS高级排程</div>
                    <div class="title2">运输、生产整体规划，需求、成本最优决策</div>
                    <div class="title3" @click="jumpRout('/APS-advanced-scheduling','2')">了解详情</div>
                </div>
                <div class="card">
                    <img src="../assets/image/example/04-0caigou.png"/>
                    <div class="title1">采购协同</div>
                    <div class="title2">管理透明，高效执行</div>
                    <div class="title3" @click="jumpRout('/purchase-collaboration','2')">了解详情</div>
                </div>
                <div class="card">
                    <img src="../assets/image/example/04-0DPM数字化工单.png"/>
                    <div class="title1">DPM数字化工单</div>
                    <div class="title2">实现工单科学管理，提升生产稳定性</div>
                    <div class="title3" @click="jumpRout('/DPM-digital-Sheet','2')">了解详情</div>
                </div>
                <div class="card">
                    <img src="../assets/image/example/04-0在线安全观察.png"/>
                    <div class="title1">在线安全观察</div>
                    <div class="title2">建造在线安全质量体系</div>
                    <div class="title3" @click="jumpRout('/safety-observation','2')">了解详情</div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',
    metaInfo:{
        meta:[
        { name:'keywords', content:'比孚,上海比孚,比孚信息,比孚信息科技,数字化生产,智能仓储,APS高级排程,采购协同,DPM数字化工单,在线安全观察' },
        ]
    },
    data() {
        return {
        };
    },

    mounted() {
        this.activeIndex = '2'
        this.newsIndex = false
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex
        });
        
    },

    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
        jumpRout(route,index){
            this.activeIndex = index
            localStorage.setItem('headId',index);
            this.$router.push(route)
        }
    },
};
</script>

<style lang="less" scoped>
.industry-box{
    width: 139rem;
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;

    margin: 4rem 0 0 26.1rem;
    .card{
        width: 45rem;
        height: 40rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 15px 0px rgba(231,231,231,0.5);

        margin-top: 4rem;
    }
    img{
        width: 41rem;
        height: 22rem;
        margin-top: 2rem;
    }
    .title1{
        height: 2.8rem;
        font-size: 2.8rem;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #000000;
        line-height: 4.2rem;
        padding: 2rem;
        text-align: justify;
    }
    .title2{
        height: 3.4rem;
        font-size: 1.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #666666;
        line-height: 3rem;
        padding: 2rem;
        padding-top: 2rem;
        text-align: justify;
    }
    .title3{
        height: 3rem;
        font-size: 1.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 3rem;
        padding-left: 2rem;
        margin-top: 3rem;
        text-align: justify;

        cursor: pointer;
    }
    .card:hover{
        transform: scale(1.05);
        transition:all 0.5s;
    }

}
</style>
